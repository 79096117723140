<template>
    <div class="container">
        <div class="title-text">
            <div class="title-text-t">国家重点研发计划“主动健康和人口老龄化科技应对”重点专项</div>
            <div class="title-text-t">《国家科学健身图数据库和推荐系统平台研究》</div>
        </div>

        <div class="box">
            <div class="pre-box">
                <h2 style="font-size: 22px;font-weight: bold;margin-top: 18px;">健身运动知识智能查询问答平台</h2>
                <div class="chat-welImg" style="margin-top: 50px;">
                    <img src="../../assets/image/pic.png" />
                </div>
            </div>
            <div class="login-form">
                <!-- <h2 style="font-size: 22px;font-weight: bold;margin-top: 18px;">登录</h2> -->
                <el-form ref="loginForm" :hide-required-asterisk="true" label-position="top" :model="loginForm"
                    :rules="loginRules" label-width="80px" class="login-forms">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="loginForm.username" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"
                            @keyup.enter="login"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="doLogin" style="width: 100%; margin-top: 5px;"
                            :loading="loading">登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { login } from '@/api/login';
import { setToken } from "@/utils/auth";
import md5 from 'js-md5'

export default {
    data() {
        return {
            loading: false,
            loginForm: {
                username: "",
                password: "",
            },
            loginRules: {
                username: [
                    { required: true, message: "请输入用户名", trigger: "blur" },
                ],
                password: [{ required: true, message: "请输入密码", trigger: "blur" }],
            },
            remember: false,
            cipher: false,
        };
    },
    mounted() { },
    methods: {
        doLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.loading = true
                    login({
                        username: this.loginForm.username,
                        password: md5(this.loginForm.password),
                    }).then(res => {
                        this.loading = false
                        setToken(res.xtoken)
                        this.$router.push({ name: "index" });
                    }).catch(err => { this.loading = false })
                }
            });
        },
    },
};
</script>

<style scoped>
.title-text {
    position: fixed;
    right: 20px;
    top: 20px;
    text-align: center;
}

.title-text-t {
    font-size: 12px;
    color: #000;
}

.title-text-t+.title-text-t {
    margin-top: 2px;
}

.box {
    width: 1050px;
    height: 600px;
    position: fixed;
    z-index: 2;
    margin: auto;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, .6);
    box-shadow: 2px 1px 19px rgba(0, 0, 0, .1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pre-box {
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 2px 1px 19px rgba(0, 0, 0, .1);
    transition: 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-form {
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-forms {
    width: 300px;
    margin-top: 50px;
}
</style>